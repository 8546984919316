import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';

import ContactPharmaMessager from 'components/contact-pharma-messager/ContactPharmaMessager';
import { useDispatch } from 'react-redux';
import { accountGetMessageThreadRoutine, accountFetchReplyMessageRoutine } from 'state/account/account.routines';
import { messageOptions } from 'const/options';
import { navigate } from 'gatsby';

export default function ContactUs() {
    const location = useLocation();
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(location.search);
    const headerId = urlParams.get('header_id');
    const messageType = messageOptions.find((option) => option.value === 'Contact Us');
    const [loadingMessages, setLoadingMessages] = useState(true);

    useEffect(() => {
        if (urlParams.has('header_id')) {
            dispatch(
                accountGetMessageThreadRoutine.trigger({
                    messageType: 'Contact Us',
                    HeaderID: headerId,
                    onSuccess: () => {
                        setLoadingMessages(false);
                    }
                })
            );
            dispatch(
                accountFetchReplyMessageRoutine.trigger({
                    messageType: 'Contact Us',
                    HeaderID: headerId
                })
            );
        } else {
            if (typeof window !== 'undefined') {
                navigate('/404');
            }
        }
    }, []);

    return <ContactPharmaMessager messageSource={messageType?.key} headerId={headerId} isLoading={loadingMessages} />;
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
